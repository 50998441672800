div {
  border: 0px dotted red;
}

html, body {
  border: 0;
  margin: 0px;
  font-family: 'Noto Sans';
  font-size: 100%;
}

body {
  background-color: #f4f4f4;
}

.bgcolor {
  background-color: white;
}

#topbar {
  position: fixed;
  z-index: 20;
  top: 0;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
}

#topbar DIV:first-child {
  display: inline-block;
  width: 70%;
  font-size: 2em;
  display: none;
  font-family: 'Roboto Slab';
}

#topbar DIV.inlineinout {
  display: block;
  position: absolute;
  top: 15px;
  right: 10px;
}

#top {
  border: 0px red solid;
  height: 100%;
  color: white;
}

#top #lefttop {
  display: inline-block;
  top: 0px;
  vertical-align: top;
  width: 20%;
  height: 100%;
  background-image: url("../images/html.jpg");
  background-size: cover;
  background-position: -100px 0px;
  box-sizing: border-box;
}

#top #righttop {
  width: 80%;
  display: inline-block;
  box-sizing: border-box;
}

#topmiddle {
  width: 100%;
  border: 0px solid blue;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

#topmiddle DIV:first-child {
  font-weight: 700;
  font-size: 7vw;
  height: auto;
  width: 100%;
  color: black;
}

#topbottom {
  height: auto;
  font-size: 2em;
  border: 0px solid black;
  position: absolute;
  background-color: white;
  bottom: 0px;
  color: black;
  left: calc(50% - 50px);
  width: 100px;
  text-align: center;
}

#topbottom:after {
  /*background: #000000;
    content: '';
    height: 30px;
    left: 50%;
    position: absolute;
    top: 100%;
    width: 5px;*/
}

#topmiddle DIV:nth-child(2) {
  height: 20px;
  overflow: hidden;
  display: block;
  width: 100%;
  opacity: .7;
}

#topmiddle DIV:nth-child(3) {
  width: 100%;
  font-size: 3vw;
  color: black;
  text-align: center;
  line-height: 4vw;
  padding: 10px;
  box-sizing: border-box;
}

#philosophy {
  padding: 20px 0px 120px 0px;
  color: white;
  background-color: #4d94ff;
}

#philosophy #philtext {
  width: 90%;
  margin-left: 5%;
  text-align: center;
}

.sectiontitle {
  font-family: "Roboto Slab";
  font-size: 6vw;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

#header {
  background-color: blue;
  width: 25%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  padding: 6em 3em 3em 3em;
  text-align: right;
  height: 100%;
}

#features {
  padding-bottom: 120px;
}

#feabody {
  vertical-align: top;
}

#feabodylt {
  width: 46%;
  margin-left: 2%;
  margin-right: 2%;
}

#feabodylt, #feabodyrt {
  box-sizing: border-box;
  display: inline-block;
  text-align: right;
}

#feabodyrt {
  padding: 5px;
  box-sizing: border-box;
  background-color: black;
  color: white;
  vertical-align: top;
  text-align: center;
  width: 46%;
  margin-left: 2%;
  margin-right: 2%;
}

#pricing {
  padding-bottom: 120px;
  color: white;
  background-color: #4d94ff;
}

#current {
  height: 50%;
}

#contact {
  padding-bottom: 120px;
  color: white;
  background-color: #4d94ff;
}

.gentext {
  font-size: 2.5vw;
  line-height: 5vw;
  text-align: center;
  font-family: 'Libre Franklin';
}

.money {
  font-family: 'Heebo';
}

.splitbox1 {
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
}

#moneyboxes {
  text-align: center;
}

.splitbox2 {
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
}

#contactarea {
  text-align: center;
  margin-top: 10px;
}

#contactarea DIV {
  padding: 5px 0px;
}

#contactarea DIV INPUT {
  margin: 0px auto;
  width: 250px;
}

@media screen and (max-width: 650px) {
  #feabodylt, #feabodyrt {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
  }
  .sectiontitle {
    font-size: 9vw;
    font-family: 'Roboto Slab';
  }
  .gentext {
    font-size: 6vw;
    line-height: 8.5vw;
  }
  #top #righttop {
    display: block;
    width: 100%;
    height: 60%;
  }
  #top #lefttop {
    display: block;
    width: 100%;
    height: 20%;
    margin-top: 10%;
    background-size: cover;
    background-position: 0px 0px;
  }
  #philosophy #philrt {
    width: 100%;
  }
  #philosophy #philrt UL LI {
    text-align: center;
  }
  #topbar {
    height: 40px;
  }
  #topbar DIV:first-child {
    font-size: 1.5em;
  }
  #topbar #signin {
    top: 10px;
    font-size: .8em;
  }
  #topmiddle DIV:first-child {
    font-weight: 700;
    font-size: 10vw;
    height: auto;
    text-align: center;
  }
  #topmiddle DIV:nth-child(2) {
    background-size: 125% 125%;
    height: 40px;
  }
  #topmiddle DIV:nth-child(3) {
    width: 100%;
    line-height: 10vw;
    font-size: 7vw;
    text-align: center;
  }
  #topbottom {
    bottom: 20px;
    height: 20px;
    line-height: 20px;
    font-size: .8em;
  }
  #topbottom:after {
    height: 20px;
  }
}
