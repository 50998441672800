#usercontain {
  margin-top: 40px;
  padding: 20px;
  box-sizing: border-box;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);
}

.infoarea {
  margin: 5px;
  margin-bottom: 30px;
  padding: 10px;
  box-sizing: border-box;
}

.userpayment, .userpaymentdone {
  position: relative;
  border-bottom: 2px solid grey;
}

.userpayment SPAN:nth-child(1), .userpaymentdone SPAN:nth-child(1), .userpaymentdone SPAN:nth-child(2) {
  width: 100%;
  display: block;
}

.userpayment SPAN:nth-child(3) {
  width: 100%;
  display: block;
}

.userpaymentdone SPAN:nth-child(4) {
  width: 100%;
  display: block;
}

.userpayment SPAN:nth-child(4) {
  padding: 3px;
  top: 0px;
  position: absolute;
  right: 0px;
  font-size: 1.1em;
  color: white;
}

.containbox {
  background-color: white;
  /*box-shadow: 5px 5px;*/
  margin: 5px 5px 1% 1%;
  padding: 5px 5px 1% 1%;
  vertical-align: top;
}

.containboxhalf {
  margin: 5px 5px 1% 1%;
  padding: 5px 5px 1% 1%;
  width: 48%;
  display: inline-block;
  background-color: white;
  vertical-align: top;
}

.sectionhead {
  background-color: black;
  color: white;
  padding: 10px;
  font-size: 1.3em;
  font-weight: bold;
  margin-top: -15px;
  display: block;
}

.infotitle {
  font-weight: bold;
  display: block;
  font-size: 1.3em;
}

@media screen and (max-width: 650px) {
  .containboxhalf {
    margin: 5px 5px 1% 1%;
    padding: 5px 5px 1% 1%;
    display: block;
    width: auto;
    background-color: white;
    vertical-align: top;
  }
}
