#userlist{
	margin-top:30px; 
	width: 200px;
	display: inline-block;
	border: 1px grey solid;
}
.eachuser{
	margin: 5px;
	padding: 5px;
	font-size: .8em;
	background-color: grey;	
		color: white;
		display: block;

}
.label{ 
	display: block;
	width: auto;
	color: black;
	vertical-align: top;
	margin-top:10px;
	width:200px;
	text-align:left;
}

.label2{
	display: block;
	width: auto;
	color: black;
	text-align:center;
	color:white;
	background-color:black;
}
#usersettings{
	position:fixed;
	right:0px;
	top:0px;
	width: calc(100vw - 200px);
	vertical-align: top;
	color: black;
}

#usersettings .bottomthird:first-child{
	display:block;
	position:fixed;
	top:0px;
	left:500px;
	width:250px; 
	overflow-y: auto;
	vertical-align: top;
	text-align: center;
	bottom:0px;
}

#usersettings .bottomthird:nth-child(2){
	display:block;
	position:fixed;
	top:0px;
	left:750px;
	width:200px; 
	overflow-y: auto;
	vertical-align: top;
	text-align: center;
	bottom:0px;
}

#usersettings .bottomthird:nth-child(3){
	display:block;
	position:fixed;
	top:0px;
	left:950px;
	width:200px; 
	overflow-y: auto;
	vertical-align: top;
	text-align: center;
	bottom:0px; 
}

.paymentbox{
	font-size:.7em;
	margin-bottom:10px;
	display:block;
}
#sendnotice{
	background-color:blue;
	color:white;
}
.paymentbox SPAN{
	display:block;
	text-align:left;
}

TEXTAREA.noresize{
	resize:none;
	font-size:.7em;
	width:100%;
}
#usersettings .bottomthird:nth-child(4){
	display:block;
	position:fixed;
	top:0px;
	left:1150px;
	width:200px; 
	overflow-y: auto;
	vertical-align: top;
	text-align: center;
}


#invoiceholder{
	font-size: .7em;
}
#chargeholder{ 
	font-size: .7em;
}
#subscriptionholder{ 
	font-size: .7em;
}
#changeplan,#subscriptionchange,#createpayment{
	background-color: green;
	padding: 5px;
	margin: 5px;
}
.refundblock{
	background-color: red;
	color: white;
	text-align: left;
	padding: 3px;
}

.planblock{
	background-color: red;
	color: white;
	text-align: left;
	padding: 3px;
}
.invoiceblock{
	padding: 3px;
	margin: 5px;
	border: 1px solid grey;
}

.inputone{
	font-size:.8em;
	width:250px;
}
#test_dns,#actual_dns{
	font-size:.8em;
}

.tochange{
	width:255px;
	display:inline-block;
}
.chargeblock{
	padding: 3px;
	margin: 5px;
	border: 1px solid grey;
}